@import '~@ng-select/ng-select/themes/default.theme.css';
@import '~perfect-scrollbar/css/perfect-scrollbar.css';

// Overide ng-select
.ng-select-container {
  min-height: 34px !important;
  height: 34px !important;
  box-shadow: 0 0 20px #e4dddd;
  min-width: 7.9rem !important;
}

.ng-select-container.ng-has-value {
  height: auto !important;
}

// override yfiles.css

// grid snapping corner color
// https://docs.yworks.com/yfileshtml/#/dguide/customizing_view_css_styling#customizing_view_css_styling_snap_lines
.yfiles-snap-line {
  stroke: rgb(126, 134, 147);
}

// Need this to add an ability to text in the wrap label
.yfiles-canvascomponent .yfiles-labeleditbox {
  min-width: 300px;
  max-width: 300px;
  max-height: 300px;
  word-break: break-all;
  white-space: normal;
}

.yfiles-canvascomponent .yfiles-labeleditbox-container {
  min-width: 300px;
  max-width: 300px;
}
