@import 'colors.scss';

$theme-green: #1b4967;
$input-color: #647678;

$theme-colors: (
  'primary': $color-primary,
  'secondary': $color-secondary,
);

.theme-green {
  // Date Picker
  .bs-datepicker-head {
    background-color: $theme-green;
  }

  .bs-datepicker-body {
    .week span {
      color: $theme-green !important;
    }
  }
}

// google place result
.pac-container {
  z-index: 9999;
}

.toast-container {
  z-index: 99999999999;
  opacity: 1 !important;
  height: 60px;
}

.toast {
  line-height: unset !important;
  height: 60px;
  font-size: 18px !important;
}

// Css for success toastr
.toast-success {
  background-color: rgba(81, 163, 81, 0.95) !important;
}

.toast-error {
  background-color: #bd362f !important;
}

.spinner-svg {
  display: inline-block;
  vertical-align: middle;
  background-image: url('../images/spinner-loading.svg');
  width: 50px;
  height: 50px;
  background-size: 100% 100%;
  background-repeat: no-repeat;

  &.white {
    background-image: url('../images/spinner-loading-white.svg');
  }

  &.dark-blue {
    background-image: url('../images/spinner-loading-dark-blue.svg');
  }

  &.small {
    width: 25px;
    height: 25px;
  }
}

.popover.people-search {
  max-width: max-content;

  ul {
    padding-left: 1em !important;
    margin-left: 1em;
  }
}

.form-control {
  height: 34px;
  font-size: inherit;

  &::placeholder {
    color: #999;
  }
}

.has-error .form-control,
.has-error .form-control:focus,
.has-error .ng-select-container,
.form-control.ng-invalid.ng-dirty,
.form-control.ng-invalid.ng-touched {
  border-color: $color-error;
}

.ng-select {
  &.ng-invalid {
    &.ng-dirty,
    &.ng-touched {
      .ng-select-container {
        border-color: $color-error;
      }
    }
  }
}

.has-error .form-control:focus {
  outline: 0;
}

.maxsize-error {
  margin-top: 5px;
  color: $color-error;
  font-weight: 400;
  font-size: 12px;
}

form {
  .disabled {
    .c-checkbox {
      color: #cdcdcd;
    }
  }
}

.tooltip {
  z-index: 99999999;
}

.btn {
  font-size: inherit;
}

.btn:disabled {
  opacity: 0.5;
  cursor: default;
}

.btn-primary {
  background-color: $color-primary;
  border-color: #2e6da4;
  color: #fff;

  &:hover,
  &:focus,
  &:active {
    background-color: #206bae;
  }
}

.btn-primary:disabled {
  background-color: $color-primary;
  border-color: #2e6da4;
  color: #fff;

  &:hover,
  &:focus,
  &:active {
    background-color: #206bae;
  }
}

.btn-secondary {
  color: $color-secondary;
  background-color: #fff;
  border-color: #ccc;

  &:hover,
  &:focus,
  &:active {
    color: $color-secondary;
    background-color: #e6e6e6;
    border-color: #adadad;
  }
}

.btn-secondary:disabled {
  color: $color-secondary;
  background-color: #fff;
  border-color: #ccc;

  &:hover,
  &:focus,
  &:active {
    background-color: #e6e6e6;
    border-color: #adadad;
  }
}

.btn.processing {
  color: #fff0; // fully transparent - make text invisible
  background-image: url('../images/spinner-loading-white.svg');
  background-position: center;
  background-repeat: no-repeat;
  background-size: 25px 25px;
}

.modal-avatar {
  position: absolute;
  margin: auto;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 0;
  margin-top: -36px;
  text-align: center;

  img {
    border-radius: 50%;
    overflow: hidden;
    background: #fff;
    border: 1px solid #1b4967;
    width: 72px;
    height: 72px;
    object-fit: cover;
  }
}

.modal-avatar-title {
  margin-top: 5px;
  margin-left: auto;
  margin-right: auto;
}

.modal-title,
.modal-avatar-title {
  font-size: 18px;
  font-weight: bold;
}

.modal-avatar-header {
  height: 75px;
}

a.link,
a {
  color: $color-primary;
}

a.link:hover {
  cursor: pointer;
  color: #1f57ad;
  text-decoration: underline;
}

// for ng-select with people names and images
.select-avatar {
  display: inline-block;
  vertical-align: middle;
  width: 32px;
  height: 32px;
  margin-right: 5px;
  border-radius: 50%;
  overflow: hidden;
  background: #fff;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.small-avatar {
  width: 24px;
  height: 24px;
}

.form-group.form-group-button {
  text-align: right;
}

body {
  font-size: 14px;
}

.avatar {
  width: 35px;
  height: 35px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 50% 50%;
  border-radius: 50%;
  margin: 0;
}

.avatar-border-white {
  border: 2px solid #fff;
}

nav .avatar {
  margin-left: 5px;
  margin-right: 5px;
}

.bg-light {
  background-color: #ebebeb !important;
}

.bg-dark {
  background-color: $color-primary !important;
}

.header-bg {
  background-color: $header-color;
}

.form-group {
  margin-bottom: 1rem;
}
