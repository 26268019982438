@import 'colors.scss';

.wrapper,
body,
html {
  width: 100%;
  height: 100%;
}
@font-face {
  font-family: Lato;
  font-style: normal;
  font-weight: 400;
  src: local('Lato Regular'), local('Lato-Regular'), url(https://fonts.gstatic.com/s/lato/v16/S6uyw4BMUTPHjx4wWw.ttf) format('truetype');
}
@font-face {
  font-family: Lato;
  font-style: normal;
  font-weight: 700;
  src: local('Lato Bold'), local('Lato-Bold'), url(https://fonts.gstatic.com/s/lato/v16/S6u9w4BMUTPHh6UVSwiPHA.ttf) format('truetype');
}
.text-blue-dark,
.text-primary {
  color: $color-primary !important;
}
.text-gray {
  color: #8c9091;
}
body,
html {
  overflow: hidden;
  color: #000;
  background: #fff;
  font-family: Lato, sans-serif;
}

.wrapper {
  display: block;
  margin: 0 auto;
  box-shadow: 0 20px 50px rgba(0, 0, 0, 0.05);
  overflow: hidden;
  position: relative;
}
.inner {
  width: 600px;
  margin: 0 auto;
}
.bg-overlay {
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.75);
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9998;
  display: none;
}

* {
  -webkit-tap-highlight-color: transparent;
}

::-webkit-scrollbar {
  width: 6px;
  height: 6px;
  background-color: transparent;
}
::-webkit-scrollbar-track {
  background-color: transparent;
  border-radius: 6px;
}
::-webkit-scrollbar-thumb {
  border-radius: 6px;
  background-color: rgba(170, 170, 170, 0.9);
}
perfect-scrollbar {
  overflow-y: auto !important;
}

.no-content {
  text-align: center;
  padding-top: 20px;
}
.no-content p {
  margin: 20px;
  color: $color-primary;
  font-weight: 400;
  font-size: 14px;
}
button fa .fa {
  color: #fff;
}
.img-cover img {
  border-radius: 50%;
  object-fit: cover;
}
.img-shadow img {
  box-shadow: 0 0 14px 0 #dedede;
}
.toast-success {
  background-color: #1d9583 !important;
}
.toast-error {
  background-color: #b40000 !important;
}
.toast-info {
  background-color: #2e83b8 !important;
}
.toast-warning {
  background-color: #c5c300 !important;
}
.list-socials {
  display: flex;
  flex-wrap: wrap;
}
.list-socials__item {
  text-align: center;
  margin: 10px 0;
  padding: 0 10px;
  width: 20%;
}
.s-person-detail-details .list-socials__item {
  width: 25%;
}
.list-socials__link {
  display: inline-block;
  text-decoration: none;
  color: inherit;
}
.list-socials__link:hover {
  opacity: 0.5;
  text-decoration: none;
  cursor: pointer;
}
.list-socials__icon {
  line-height: 1;
}
.list-socials__icon img {
  width: 44px;
}
.spacer {
  -webkit-box-flex: 1;
  -webkit-flex-grow: 1;
  -moz-flex-grow: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
}

.table-hover.table-bordered th, .table-hover.table-bordered td {
  padding: 6px 10px !important;
}
.sidebar > .list-nav .btn-action span,
.sidebar > ul.list-action > li p span.name,
.sidebar > ul.list-action > li > ul.sub-action > li > .box-buttons .button {
  font-family: 'Open Sans', Arial, sans-serif;
  font-weight: 500;
}

form.filter {
  padding: 21px 25px 0;
  margin-bottom: 10px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-wrap: wrap;
  -moz-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}
form.filter > .form-group {
  margin-right: 10px;
  margin-bottom: 0;
  display: inline-block;
}
form.filter > .form-group.box-search {
  width: 250px;
}
form.filter > .form-group.age-range,
form.filter > .form-group.gender {
  width: 150px;
}

.m-person-detail {
  width: 430px;
  position: fixed;
  right: 0;
  top: 110px;
  bottom: 0;
  z-index: 50;
  background: #fff;
  border-left: 5px solid #2980b9;
  transform: translateX(100%);
  transition: transform 0.5s ease;
  overflow: hidden;
}

.m-person-detail.active {
  transform: translateX(0);
}
.m-person-detail__inner {
  height: 100%;
  position: relative;
  margin-top: 10px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.m-person-detail__info {
  padding: 14px 10px 0;
}
.m-person-detail__content {
  flex-grow: 1;
  padding: 0 10px;
  display: flex;
  overflow: hidden;
}
.m-person-detail__tools {
  padding-bottom: 15px;
}
.m-person-detail a.url {
  text-decoration: underline;
}
.b-plugin .ls-plugin .item,
.i-builder-control,
.i-builder-control:hover,
.m-activity__data table tbody a,
.t-person-detail-list li a {
  text-decoration: none;
}
.b-person-detail-info {
  display: flex;
  flex-wrap: wrap;
}
.b-person-detail-info__thumb {
  margin-right: 20px;
}
.b-person-detail-info__thumb img {
  width: 86px;
  height: 86px;
  object-fit: cover;
  box-shadow: 0 0 20px #e4dddd;
  border-radius: 50%;
}
.b-person-detail-info__content {
  flex: 1;
  overflow: hidden;
}
.b-person-detail-info__tools {
  margin-left: 30px;
  font-size: 12px;
}
.b-person-detail-info__title {
  font-size: 14px;
  font-weight: 700;
  color: $color-primary;
  margin-bottom: 5px;
  word-break: break-all;
}
.b-person-detail-info__image {
  width: 32px;
  height: 32px;
  border-radius: 50%;
}
.b-person-detail-info__desc {
  display: table;
  font-size: 12px;
}
.b-person-detail-info__desc dl {
  display: table-row;
}
.b-person-detail-info__desc dt {
  display: table-cell;
  padding-right: 30px;
  font-weight: 700;
}
.b-person-detail-info__desc dd {
  display: table-cell;
}
.btn-person-detail-info-edit {
  cursor: pointer;
}

.t-person-detail-list {
  display: flex;
  flex-shrink: 0;
  border-bottom: 1px solid #d8d8d8;
}

.t-person-detail-list li {
  padding: 0 25px 0 5px;
}

.t-person-detail-list li a {
  display: block;
  padding-bottom: 12px;
  font-size: 12px;
  color: $color-secondary;
  position: relative;
}

.t-person-detail-list li a.active::before {
  display: block;
  content: ' ';
  width: 10px;
  height: 10px;
  background: #fff;
  border-color: #d8d8d8;
  border-style: solid;
  border-width: 1px 0 0 1px;
  transform: rotate(45deg) translate(0, 50%);
  position: absolute;
  bottom: 0;
  left: 50%;
  margin: 0 0 -2px -2px;
}
.t-person-detail-cont {
  flex-grow: 1;
  overflow-y: auto;
  overflow-x: hidden;
}
.s-person-detail-details {
  padding: 0 10px;
}
.s-person-detail-details__head {
  font-size: 15px;
  font-weight: 700;
  padding-bottom: 5px;
  border-bottom: 1px dotted #d8d8d8;
  margin-bottom: 5px;
}
.s-person-detail-details__body {
  display: table;
  width: 100%;
  font-size: 12px;
  margin-bottom: 14px;
}
.s-person-detail-details__item {
  padding: 5px 0;
}
.s-person-detail-details__item:first-child {
  padding-top: 0;
}
.s-person-detail-details__item dl {
  display: table-row;
}
.s-person-detail-details__item dt {
  display: table-cell;
  font-weight: 700;
  width: 100px;
  padding: 5px 0;
}
.s-person-detail-details__item dd {
  display: table-cell;
}
.s-person-detail-details .icon-social {
  display: inline-block;
  margin-right: 10px;
  vertical-align: middle;
}
.s-person-detail-details .icon-social img {
  width: 20px;
}
.s-person-detail-details .txt-status {
  text-transform: uppercase;
  margin-right: 20px;
}
.s-person-detail-details .txt-view {
  font-size: 12px;
  color: #337ab7;
}
.s-person-detail-details .txt-view .url {
  margin-right: 5px;
}
.l-person-detail-history {
  padding-right: 7.5px;
  margin-left: 7.5px;
}
.l-person-detail-history__item {
  margin-top: 15px;
  padding: 6px;
  display: flex;
}
.l-person-detail-history__item:first-child {
  margin-top: 0;
}
.l-person-detail-history__thumb {
  width: 35px;
  flex-shrink: 0;
  margin-right: 15px;
}
.l-person-detail-history__thumb img {
  width: 35px;
  height: 35px;
  object-fit: cover;
  border-radius: 50%;
}
.l-person-detail-history__conts {
  flex-grow: 1;
  line-height: 1.25;
}
.l-person-detail-history__title {
  font-size: 12px;
  margin-bottom: 2px;
}
.l-person-detail-history__date {
  font-size: 10px;
  margin-bottom: 2px;
}
.l-person-detail-history__desc {
  margin-bottom: 2px;
  word-break: break-word;
}
.m-person-detail__tools {
  position: relative;
  margin-top: 15px;
}
.l-person-detail-tool {
  display: flex;
  justify-content: space-around;
  position: relative;
  z-index: 10;
}
.l-person-detail-tool li {
  text-align: center;
}
.l-person-detail-tool li .desc {
  display: block;
  font-size: 12px;
  color: $color-primary;
  margin-top: 3px;
}
.l-person-detail-tool li > div {
  display: block;
  padding-top: 20px;
  text-decoration: none;
  position: relative;
}
.l-person-detail-tool li > div.active::before {
  display: block;
  content: ' ';
  width: 10px;
  height: 10px;
  background: #fff;
  border-color: #d8d8d8;
  border-style: solid;
  border-width: 0 1px 1px 0;
  transform: rotate(45deg) translate(-50%, 0);
  position: absolute;
  top: 0;
  left: 50%;
  margin: -2px 0 0 -2px;
}
.b-person-detail-tool {
  position: absolute;
  bottom: 100%;
  left: 0;
  width: 100%;
  background: #fff;
}
.b-person-detail-tool__inner {
  border-bottom: 1px solid #d8d8d8;
  position: relative;
}
.b-person-detail-tool__inner div[data-tool-content] {
  padding: 10px 0;
}
.f-person-detail-tool {
  position: relative;
}
.f-person-detail-tool__title {
  font-size: 16px;
  font-weight: 700;
  color: $color-primary;
  padding: 0 15px;
}
.f-person-detail-tool__button {
  position: absolute;
  right: 0;
  bottom: -8px;
}

.f-person-detail-tool__close {
  position: absolute;
  top: 0;
  right: 15px;
  font-size: 20px;
  color: $color-primary;
  line-height: 1;
  cursor: pointer;
}
.b-person-detail-tool .f-person-detail-tool__content {
  overflow-x: hidden;
  overflow-y: auto;
  padding: 15px 15px 0;
}
.b-person-detail-tool .f-person-detail-tool__content form[role='form'] {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-direction: normal;
  -webkit-box-orient: vertical;
  -webkit-flex-direction: column;
  -moz-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  height: calc(100vh - 375px);
}
.f-person-detail-tool__content form[role='form'] .form-group.spacer {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-direction: normal;
  -webkit-box-orient: vertical;
  -webkit-flex-direction: column;
  -moz-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
}
.f-person-detail-tool__content form[role='form'] .form-group.spacer input-control * {
  height: 100%;
}
.b-person-detail-edit {
  position: absolute;
  left: 0;
  top: 0;
  background: #fff;
  z-index: 99999;
  width: 100%;
  height: 100%;
  overflow-y: auto;
}
.b-person-detail-edit__inner {
  padding: 15px;
}
.b-person-detail-edit__close {
  font-size: 16px;
  color: $color-primary;
  position: fixed;
  right: 15px;
  cursor: pointer;
  z-index: 1;
}
.b-person-detail-edit__thumb {
  margin-bottom: 10px;
}
.b-person-detail-edit__thumb img {
  width: 86px;
  height: 86px;
  object-fit: cover;
  box-shadow: 0 0 20px #e4dddd;
  border-radius: 50%;
}
.b-person-detail-edit__button {
  margin-top: 15px;
  text-align: right;
}
.l-person-detail-edit > .form-group {
  display: flex;
  flex-wrap: wrap;
}
.l-person-detail-edit > .form-group > * {
  width: 60%;
}
.l-person-detail-edit > .form-group > .lable {
  width: 40%;
  padding-right: 10px;
  padding-top: 5px;
}
.l-person-detail-edit .form-inline {
  display: flex;
  margin: 0 -3px;
}
.l-person-detail-edit .form-inline .form-group {
  width: 50%;
  padding: 0 3px;
  margin-bottom: 5px;
}
.l-person-detail-edit .form-inline .form-group .form-control {
  width: 100%;
}
.b-person-detail-list {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.modal:not(modal-container) {
  display: block;
  position: fixed;
  left: 50%;
  top: 50%;
  bottom: auto;
  background: #fff;
  border: 1px solid #d8d8d8;
  box-shadow: 0 0 20px #e4dddd;
  z-index: 999999;
  width: 430px;
  transform: translate(-50%, -50%);
  overflow: unset !important;
}
.modal > .header .title {
  display: block;
  padding: 20px 20px 0;
  font-size: 16px;
  font-weight: 700;
  color: $color-primary;
}
.modal > .header .icon {
  float: none;
  position: absolute;
  top: 15px;
  right: 20px;
}
.modal > .header .icon.close {
  opacity: 1;
}
.modal .body {
  padding: 20px;
}

.form-title {
  background: #f9f9f9;
  font-size: 20px;
  color: #1b4967;
  padding: 10px 20px;
  margin: 0 -20px 15px;
  line-height: normal;
}
.icon-doc,
.icon-exe,
.icon-file,
.icon-mp4,
.icon-pdf,
.icon-ppt,
.icon-xls,
.icon-zip {
  display: inline-block;
  vertical-align: middle;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  width: 64px;
  height: 76px;
}
section.my-case .wrap .list .box .inner .content .detail .bottom,
section.my-case .wrap .list .box .inner .content .detail .gender,
section.my-case .wrap ul.sub-action {
  display: none;
}
.icon-doc {
  background-image: url(../images/iconsfile/icon-doc.svg);
}
.icon-exe {
  background-image: url(../images/iconsfile/icon-exe.svg);
}
.icon-file {
  background-image: url(../images/iconsfile/icon-file.svg);
}
.icon-mp4 {
  background-image: url(../images/iconsfile/icon-mp4.svg);
}
.icon-pdf {
  background-image: url(../images/iconsfile/icon-pdf.svg);
}
.icon-ppt {
  background-image: url(../images/iconsfile/icon-ppt.svg);
}
.icon-xls {
  background-image: url(../images/iconsfile/icon-xls.svg);
}
.icon-zip {
  background-image: url(../images/iconsfile/icon-zip.svg);
}
section.login {
  width: 100%;
  height: 100%;
  background: #fff;
}
section.login > .inner {
  text-align: center;
  width: 234px;
  transform: translate(-50%, -50%);
  left: 50%;
  top: 50%;
  position: fixed;
}
section.login > .inner > a.logo img {
  width: 178px;
}
section.login > .inner > form {
  margin-top: 45px;
}
section.login > .inner > form .form-control {
  margin-bottom: 11px;
}
section.login > .inner > form .button {
  width: 100%;
  height: 30px;
}
section.my-case {
  position: relative;
}
section.my-case .container-fluid {
  padding: 0;
  position: relative;
}
section.my-case .container-fluid .type-view {
  position: absolute;
  top: -45px;
  right: 20px;
}
section.my-case .container-fluid .type-view .icon {
  margin-left: 10px;
}
section.my-case .wrap {
  position: relative;
  overflow-y: auto;
  height: calc(100vh - 125px);
}
section.my-case .wrap-inner {
  padding: 10px 20px 100px;
}
section.my-case .wrap .medium .inner .content {
  position: relative;
  top: 0;
  cursor: pointer;
}
section.my-case .wrap .medium .inner .content .detail .bottom {
  height: 105px;
}
section.my-case .wrap .list .box {
  width: 100%;
  height: 178px;
  float: none;
  margin-bottom: 10px;
}
section.my-case .wrap .list .box .inner .content .picture {
  margin: 10px;
  width: 158px;
  height: 158px;
}
section.my-case .wrap .list .box .inner .content .detail {
  width: 100%;
  height: 148px;
  position: absolute;
  left: 178px;
  top: 10px;
  text-align: left;
}
section.my-case .wrap .list .box .inner .content .detail p.name {
  margin: 0 0 15px;
  font-size: 20px;
}
section.my-case .wrap .list .box .inner .content .detail p.p12 {
  font-size: 14px;
}
section.my-case .wrap .list .box ul.sub-action {
  width: 290px;
  display: block;
  position: absolute;
  right: 10px;
  top: 10px;
  text-align: left;
}
section.my-case .wrap .list .box ul.sub-action li img {
  border-radius: 50%;
  width: 50px;
  height: 50px;
  margin-right: 10px;
  float: left;
}
section.my-case .wrap .list .box ul.sub-action li p {
  font-size: 14px;
  line-height: 16px;
  padding: 0;
  margin: 0;
}
section.my-case .wrap .list .box ul.sub-action li p i.case,
section.my-case .wrap .list .box ul.sub-action li p span.name {
  font-weight: 500;
  font-size: 16px;
}
section.my-case .wrap .big .inner .content {
  cursor: pointer;
}
section.my-case .wrap .big .inner .content .detail {
  height: 148px;
}
section.my-case .wrap .pending .box .lable {
  display: block;
}
section.my-case .wrap .inner .content .detail {
  width: 100%;
  position: relative;
}
section.my-case .wrap .box {
  width: 100%;
  height: 100%;
  margin-bottom: 0;
}
section.my-case .wrap .box.active {
  box-shadow: 0 0 0 3px #47a4e2 inset;
}
section.my-case .wrap .box.active .inner {
  border-color: #47a4e2;
}
section.my-case .wrap .box .lable {
  display: none;
}
section.my-case .wrap .box .inner {
  padding: 0;
  background: 0 0;
}
section.my-case .wrap .box .gender {
  position: relative;
  top: -6px;
}
section.my-case .wrap .box .picture {
  margin: 5px;
  width: calc(100% - 10px);
  height: 0;
  padding-bottom: calc(100% - 10px);
  background-position: 50% 50%;
  background-size: cover;
  border-radius: 50%;
}
section.my-case .wrap .box p.name {
  margin: 8px 5px;
  display: -webkit-box;
  max-height: 35px;
  -webkit-line-clamp: 2;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-box-orient: vertical;
}

.case-grid {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin: 0 -5px;
}
.case-grid::after {
  content: '';
  flex: auto;
}
.case-grid__item {
  width: 6.25%;
  padding: 5px;
  min-width: 122px;
  margin-bottom: 7px;
}
@media screen and (max-width: 1820px) {
  .case-grid__item {
    width: 6.66667%;
  }
}
@media screen and (max-width: 1720px) {
  .case-grid__item {
    width: 7.14286%;
  }
}
@media screen and (max-width: 1620px) {
  .case-grid__item {
    width: 7.69231%;
  }
}
@media screen and (max-width: 1520px) {
  .case-grid__item {
    width: 8.33333%;
  }
}
@media screen and (max-width: 1420px) {
  .case-grid__item {
    width: 9.09091%;
  }
}
@media screen and (max-width: 1320px) {
  .case-grid__item {
    width: 10%;
  }
}
@media screen and (max-width: 1220px) {
  .case-grid__item {
    width: 11.11111%;
  }
}
@media screen and (max-width: 1120px) {
  .case-grid__item {
    width: 12.5%;
  }
}
@media screen and (max-width: 1020px) {
  .case-grid__item {
    width: 14.28571%;
  }
}
@media screen and (max-width: 920px) {
  .case-grid__item {
    width: 16.66667%;
  }
}
@media screen and (max-width: 820px) {
  .case-grid__item {
    width: 20%;
  }
}
@media screen and (max-width: 720px) {
  .case-grid__item {
    width: 25%;
  }
}
@media screen and (max-width: 620px) {
  .case-grid__item {
    width: 33.33333%;
  }
}
@media screen and (max-width: 520px) {
  .case-grid__item {
    width: 50%;
  }
}
section.cases {
  position: relative;
  height: calc(100% - 60px);
}
section.chart {
  padding: 20px;
  overflow-y: auto;
  position: relative;
  height: calc(100% - 110px);
}
section.chart .add-individual {
  opacity: 0;
  display: none;
}

section.maps {
  height: calc(100% - 110px);
}
section.maps #mapsId {
  height: 100%;
}
.b-case-detail {
  height: calc(100% - 110px) !important;
  overflow-y: auto;
  min-height: 300px;
  background-color: #fff;
}
.b-case-detail .form-control {
  font-family: inherit !important;
  font-size: inherit;
  color: inherit !important;
}
.s-case-info {
  padding: 28px 0 18px;
}
.m-case-info {
  display: flex;
}
.m-case-info .avatar {
  margin-right: 35px;
  flex-shrink: 0;
  width: 175px;
}
.m-case-info .avatar > img {
  width: 175px;
  height: 175px;
  object-fit: cover;
}
.m-case-info .content {
  flex-grow: 1;
}
.m-case-info .content .form-group {
  margin-bottom: 0;
}
.m-case-info .content .name {
  font-size: 32px;
  font-weight: 700;
  color: $color-primary;
  margin-bottom: 10px;
}
.m-case-info .content .name .form-control {
  height: auto;
  padding: 0 12px;
  color: $color-primary !important;
  margin-bottom: 4px;
}
.m-case-info .content .info {
  display: table;
  width: 100%;
}
.m-case-info .content .info .form-group {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}
.m-case-info .content .info .form-group > * {
  width: 50%;
}

.m-siblings {
  display: flex;
  flex-wrap: wrap;
}
.m-siblings .item {
  width: 86px;
  margin: 0 10px 10px 0;
}
.m-siblings .item .name {
  font-size: 12px;
  line-height: 1.25;
  text-align: center;
  color: $color-primary;
}
.s-case-button {
  padding: 0 20px;
  text-align: right;
}
.t-case-detail {
  display: table;
  width: 100%;
}
.t-case-detail__item {
  display: table-row;
}
.t-case-detail__title {
  display: table-cell;
  width: 30%;
}
.t-case-detail__conts {
  display: table-cell;
  text-align: right;
}
.case-relationship .wrap .list .box .inner .content .detail .bottom,
.case-relationship .wrap .list .box .inner .content .detail .gender,
.case-relationship .wrap ul.sub-action {
  display: none;
}

.case-relationship .container-fluid {
  padding: 0;
  position: relative;
  height: calc(100% - 72px);
}
.case-relationship .container-fluid .type-view {
  position: absolute;
  top: 10px;
  right: 20px;
  z-index: 999;
}
.case-relationship .container-fluid .type-view .icon {
  margin-left: 10px;
}
.case-relationship .wrap {
  padding: 5px 10px 0;
  position: relative;
  overflow-y: auto;
  height: 100%;
}
.case-relationship .wrap .medium .inner .content {
  position: absolute;
  top: 0;
  transition: 0.5s 0.3s cubic-bezier(0.23, 1, 0.32, 1);
  cursor: pointer;
}
.case-relationship .wrap .medium .inner .content:hover {
  top: -105px;
}
.case-relationship .wrap .medium .inner .content .detail {
  height: 148px;
}
.case-relationship .wrap .medium .inner .content .detail .bottom {
  height: 105px;
}
.case-relationship .wrap .list .box {
  width: 100%;
  height: 178px;
  float: none;
  margin-bottom: 10px;
}
.case-relationship .wrap .list .box .inner .content .picture {
  margin: 10px;
  width: 158px;
  height: 158px;
}
.case-relationship .wrap .list .box .inner .content .detail {
  width: 100%;
  height: 148px;
  position: absolute;
  left: 178px;
  top: 10px;
  text-align: left;
}
.case-relationship .wrap .list .box .inner .content .detail p.name {
  margin: 0 0 15px;
  font-size: 20px;
}
.case-relationship .wrap .list .box .inner .content .detail p.p12 {
  font-size: 14px;
}
.case-relationship .wrap .list .box ul.sub-action {
  width: 290px;
  display: block;
  position: absolute;
  right: 10px;
  top: 10px;
  text-align: left;
}
.case-relationship .wrap .list .box ul.sub-action li img {
  border-radius: 50%;
  width: 50px;
  height: 50px;
  margin-right: 10px;
  float: left;
}
.case-relationship .wrap .list .box ul.sub-action li p {
  font-size: 14px;
  line-height: 16px;
  padding: 0;
  margin: 0;
}
.case-relationship .wrap .list .box ul.sub-action li p i.case,
.case-relationship .wrap .list .box ul.sub-action li p span.name {
  font-family: 'Open Sans', Arial, sans-serif;
  font-weight: 500;
  font-size: 16px;
}
.case-relationship .wrap .big .inner .content .detail {
  height: 116px;
}
.case-relationship .wrap .pending .box .lable {
  display: block;
}
.case-relationship .wrap .inner .content .detail {
  width: 100%;
  height: 148px;
  position: relative;
}
.case-relationship .wrap .box {
  float: left;
  margin: 5px;
}
.case-relationship .wrap .box .lable {
  display: none;
}
.case-relationship .wrap .box .inner {
  padding: 0;
}
.case-relationship .wrap .box .gender {
  position: relative;
  top: -6px;
}
.case-relationship .wrap .box .picture {
  margin: 5px;
}
.case-relationship .wrap .box p.name {
  margin: 12px 16px;
}
.case-relationship .wrap .box p.more-info {
  font-size: 12px;
  line-height: 17px;
  height: 62px;
  padding: 10px;
}

section.add-case,
section.edit-case {
  height: calc(100% - 130px) !important;
  overflow-y: auto;
}
section.add-case .wrap-inner,
section.edit-case .wrap-inner {
  width: 600px;
  margin: 50px auto 0;
}
section.research .search-auto-image,
section.research .search-i {
  margin-right: 5px;
  height: 35px;
  border-radius: 50%;
  width: 35px;
}
section.add-case .wrap-inner .form-control,
section.edit-case .wrap-inner .form-control {
  text-align: left;
}
section.add-case .wrap-inner .lable,
section.edit-case .wrap-inner .lable {
  line-height: 30px;
  font-size: 16px;
  font-weight: 400;
  margin-bottom: 5px;
  display: block;
}
.box-upload.rectangle .fileupload .btn > i,
.box-upload.show-review-avatar .review-avatar .file-icon,
.box-upload.square .fileupload .btn > i {
  font-size: 30px;
}
section.edit-case {
  height: calc(100% - 180px) !important;
}
.box-upload {
  width: 175px;
  height: 175px;
  border: 1px solid #dedede;
  box-shadow: 0 0 20px #dedede;
  border-radius: 50%;
  position: relative;
  perspective: 1000;
}
.box-upload .review-filename {
  position: absolute;
  left: 100%;
  top: 50%;
  margin-left: 15px;
  width: 290px;
  overflow: hidden;
  text-overflow: ellipsis;
  transform: translateY(-50%);
  display: none;
}
.box-upload .face,
.box-upload.show-review-avatar .review-avatar {
  width: 100%;
  height: 100%;
  backface-visibility: hidden;
  position: absolute;
}
.box-upload.show-review-avatar {
  cursor: pointer;
}
.box-upload.show-review-avatar:hover .fileupload .review-avatar {
  opacity: 0;
  z-index: -1;
}
.box-upload.show-review-avatar .review-avatar {
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;
  left: 0;
  background: #f2f2f2;
  color: #1b4967;
  border-radius: 50%;
  transition: all 0.5s cubic-bezier(0.23, 1, 0.32, 1);
  z-index: 1;
}
.box-upload.show-review-avatar .review-avatar .file-name {
  position: absolute;
  left: 100%;
  top: 50%;
}
.box-upload.show-review-avatar .review-filename {
  display: block;
}
.box-upload.show-review-avatar .btn {
  display: flex;
}
.box-upload.square {
  width: 82px;
  height: 82px;
  border-radius: 0;
}
.box-upload.square .fileupload .btn,
.box-upload.square .fileupload .review-avatar {
  border-radius: 0;
}
.box-upload.rectangle {
  width: 143px;
  height: 82px;
  border-radius: 0;
}
.box-upload.rectangle .fileupload .review-avatar {
  border-radius: 0 !important;
}
.box-upload.rectangle .fileupload .btn {
  border-radius: 0;
}
.fileupload {
  display: flex;
  flex-grow: 1;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  margin-bottom: 0;
}
.fileupload .review-avatar {
  width: 100%;
  height: 100%;
  display: none;
}
.fileupload .btn {
  width: 100%;
  height: 100%;
  padding: 0;
  border: none;
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-family: inherit;
  font-weight: 700;
  box-shadow: none;
  border-radius: 50%;
}

.fileupload .btn > i {
  font-size: 60px;
}
.fileupload .btn > input {
  transform: none;
  width: 100%;
  height: 100%;
}
.fileupload .btn:hover {
  background: 0 0;
  color: #647678;
}
.fileupload span.text {
  font-size: 12px;
  line-height: 1;
  margin-top: 10px;
  display: block;
  color: #647678;
}

section.research {
  width: 100%;
  height: 100%;
  position: relative;
}
section.research #txtSearch {
  margin-bottom: 5px;
}
section.research .autocomplete {
  position: relative;
  display: inline-block;
}
section.research .autocomplete-items {
  font-family: ProximaNova, sans-serif !important;
  color: #647678 !important;
  position: absolute;
  border-bottom: none;
  border-top: none;
  z-index: 99;
  top: 100%;
  left: 0;
  right: 0;
}
section.research .autocomplete-items .content-result {
  box-shadow: 0 0 20px #e4dddd !important;
  border-radius: 5px;
  width: 100%;
  margin-bottom: 5px;
  padding: 10px;
  background-color: #fff;
  display: inline-flex;
}
section.research .autocomplete-items .content-result .name-result {
  width: 70%;
  margin-top: 10px;
}
section.research .autocomplete-items .content-result .date-result {
  width: 30%;
  text-align: right;
  margin-top: 12px;
  font-size: 12px;
}
section.research .autocomplete-items .content-result:hover {
  background-color: #008eb6;
  color: #000;
}
section.research .search-i {
  background: #ebebeb;
  border: 1px solid #d8d8d8;
}
section.research .icon-user {
  margin: 3px 5px 5px 7px;
}
section.research .search-result {
  margin-top: 0;
}
section.research .add-document {
  border: 1px solid #d8d8d8;
  background: #ebebeb;
  width: 35px;
  height: 35px;
  border-radius: 50%;
  position: absolute;
  top: 30px;
  right: 30px;
  padding: 5px;
  cursor: pointer;
  transition: all 0.3s cubic-bezier(0.23, 1, 0.32, 1);
  opacity: 1;
}
section.research .add-document:hover {
  opacity: 0.8;
}
section.research ul.list-items {
  margin: 20px;
}
section.research ul.list-items li {
  width: 402px;
  height: 102px;
  margin-bottom: 10px;
  margin-right: 10px;
  display: inline-block;
  box-shadow: 0 0 20px #dedede;
  transition: all 0.3s cubic-bezier(0.23, 1, 0.32, 1);
  padding: 10px;
  border-radius: 4px;
  border-bottom: solid 3px #2980b9;
  position: relative;
  overflow: hidden;
}
section.research ul.list-items li:hover .box-icon {
  opacity: 1;
}
section.research ul.list-items li .box-icon {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  transition: opacity 0.3s cubic-bezier(0.23, 1, 0.32, 1);
}
section.research ul.list-items li .box-icon > .bg-over {
  width: 100%;
  height: 100%;
  background-color: #2980b9;
  opacity: 0.9;
  position: absolute;
  top: 0;
  left: 0;
}
section.research ul.list-items li .box-icon > i.fa {
  padding: 12px 16px;
  background-color: #fff;
  border-radius: 4px;
  transform: translate(-50%, -50%);
  left: 35%;
  margin-right: 5px;
  margin-bottom: 5px;
  top: 50%;
  position: relative;
}
.lyt-form.bg-blue > .inner a.logo,
.lyt-form.bg-green > .inner a.logo {
  margin-bottom: 30px;
}

.lyt-form {
  width: 100%;
  height: 100%;
}
.lyt-form .wrap-lable.msg-blue {
  background-color: #2980b9;
}
.lyt-form .wrap-lable.msg-red {
  background-color: #ff530d;
}
.lyt-form .wrap-lable.msg-green {
  background-color: #44bba4;
}
.lyt-form.bg-blue {
  background-color: $color-primary;
}
.lyt-form.bg-blue > .inner {
  width: 493px;
}
.lyt-form.bg-blue > .inner a.logo > img {
  width: 160px;
}
.lyt-form.bg-blue > .inner .txt-notes {
  color: #fff;
  margin-top: 20px;
}
.lyt-form.bg-green {
  background-color: #44bba4;
}
.lyt-form.bg-green > .inner {
  width: 493px;
}
.lyt-form.bg-green > .inner .box-info .title {
  color: #44bba4;
}
.lyt-form.bg-green > .inner .button.blue-dark {
  background: #44bba4;
  border: 2px solid #44bba4;
}
.lyt-form.bg-green > .inner a.logo > img {
  width: 160px;
}
.lyt-form.bg-green > .inner .txt-notes {
  color: #fff;
  margin-top: 20px;
}
.lyt-form > .wrap-lable {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  font-size: 14px;
  font-family: 'Open Sans', Arial, sans-serif;
  font-weight: 400;
  line-height: 18px;
  color: #fff;
  padding: 16px;
  text-align: center;
}
.lyt-form > .inner {
  text-align: center;
  width: 373px;
  transform: translate(-50%, -50%);
  left: 50%;
  top: 50%;
  position: fixed;
}
.lyt-form > .inner a.logo {
  margin-bottom: 45px;
  display: inline-block;
}
.lyt-form > .inner a.logo > img {
  width: 36px;
  display: inline-block;
}
.lyt-form > .inner a.logo > span {
  display: inline-block;
  vertical-align: top;
  line-height: 36px;
  font-size: 18px;
  font-family: 'Open Sans', Arial, sans-serif;
  font-weight: 500;
  color: $color-primary;
  margin-left: 12px;
}
.lyt-form > .inner a.link {
  color: #008eb6;
  font-size: 12px;
  font-weight: 400;
  transition: text-decoration 0.3s cubic-bezier(0.23, 1, 0.32, 1);
}
.lyt-form > .inner .txt-reset-pwd {
  position: absolute;
  top: 8px;
  left: 0;
}
.lyt-form > .inner .txt-notes {
  color: $color-primary;
  font-size: 12px;
  font-weight: 400;
  margin-top: 30px;
}
.lyt-form > .inner .text-lable {
  font-size: 15px;
  font-family: 'Open Sans', Arial, sans-serif;
  font-weight: 400;
  line-height: 20px;
  color: $color-primary;
  margin-bottom: 35px;
}
.lyt-form > .inner .box-info .text,
.lyt-form > .inner .box-info .title {
  font-family: 'Open Sans', Arial, sans-serif;
  color: $color-primary;
  margin-bottom: 30px;
}
.lyt-form > .inner .form-group.form-group-button {
  position: relative;
}
.lyt-form > .inner .form-control {
  text-align: left;
}
.lyt-form > .inner .has-error .form-control {
  -webkit-box-shadow: 0 0 20px #dedede;
  box-shadow: 0 0 20px #dedede;
}
.lyt-form > .inner .has-error .form-control:focus {
  border-color: #843534;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 6px #ce8483;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 6px #ce8483;
}
.lyt-form > .inner .box-info {
  width: 100%;
  padding: 50px 60px;
  border-radius: 10px;
  background-color: #fff;
}
.lyt-form > .inner .box-info .title {
  font-size: 18px;
  font-weight: 500;
  line-height: 1.2;
}
.lyt-form > .inner .box-info .text {
  font-size: 15px;
  font-weight: 400;
  line-height: 20px;
}

.btn-green:hover {
  color: #40c353 !important;
}
.btn-orange:hover:not(:disabled) {
  color: #e89c30 !important;
}
.btn-red:hover:not(:disabled) {
  color: #e8332a !important;
}

.form-group .lable {
  padding-top: 6px;
  min-height: 34px;
}
.form-group .lable input,
.form-group .lable span {
  vertical-align: middle;
}

.s-documents {
  height: calc(100vh - 176px);
  overflow-y: auto;
  margin: 1px 0;
}
.s-documents__inner {
  padding: 10px 20px 20px;
}
.s-documents.no-filter {
  height: calc(100vh - 111px);
}
.s-documents.no-filter__inner {
  padding: 20px;
}
.m-documents {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -5px;
}
.m-documents--full .m-documents {
  margin: 0;
  padding: 5px 0;
}
.m-documents .item {
  width: 25%;
  padding: 0 5px;
  margin-bottom: 10px;
}
@media screen and (max-width: 1280px) {
  .m-documents .item {
    width: 33.33333%;
  }
}
@media screen and (max-width: 980px) {
  .m-documents .item {
    width: 50%;
  }
}
@media screen and (max-width: 640px) {
  .m-documents .item {
    width: 100%;
  }
}
.m-documents .item.active .delete {
  opacity: 1;
  z-index: 15;
}
.m-documents .item.active .tools {
  opacity: 0 !important;
}
.m-documents .delete,
.m-documents .tools {
  position: absolute;
  border-radius: 4px;
  opacity: 0;
  left: 0;
  transition: opacity 0.3s ease;
}
.m-documents--full .m-documents .item {
  width: 100% !important;
}
.m-documents .wrap {
  background: #fff;
  padding: 10px;
  box-shadow: 0 0 20px #e4dddd;
  border-radius: 4px;
  border-bottom: 3px solid #2980b9;
  display: flex;
  flex-wrap: wrap;
  height: 100%;
}
.m-documents .wrap .thumb {
  display: flex;
  flex-grow: 1;
  justify-content: center;
  align-items: center;
  background: #f2f2f2;
  width: 143px;
  height: 86px;
  margin-right: 15px;
}
.m-documents .wrap .thumb > img {
  height: 100%;
  width: 100%;
}
.m-documents .wrap .conts {
  width: calc(100% - 160px);
  color: $color-primary;
}
.m-documents .wrap .conts .title {
  font-size: 15px;
  font-weight: 700;
  margin-bottom: 5px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.m-documents .wrap .conts .cate {
  font-size: 12px;
  font-weight: 700;
  margin-bottom: 3px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.m-documents .wrap .conts .desc {
  font-size: 12px;
  line-height: 1.25;
  display: -webkit-box;
  max-height: 45px;
  overflow: hidden;
}
.m-documents .tools {
  top: 0;
  z-index: 10;
  width: 100%;
  height: 100%;
  background: rgba(41, 128, 185, 0.9);
}
.m-documents .ls-tools {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 100%;
  text-align: center;
  font-size: 0;
}
.m-documents .ls-tools .btn {
  margin: 0 3px;
  padding: 0;
  width: 55px;
  height: 55px;
  line-height: 55px;
  background: #fff;
  font-size: 28px;
  text-align: center;
  outline: 0;
  color: #337ab7;
}
.m-documents .ls-tools .btn.btn-orange {
  font-size: 18px;
}
.m-documents .delete {
  top: 0;
  z-index: 5;
  width: 100%;
  height: 100%;
  background: rgba(232, 51, 42, 0.9);
  align-items: center;
  display: flex;
}
.m-documents .delete .inside {
  flex: 1;
  padding: 10px;
  text-align: center;
  color: #fff;
}
.m-documents .delete .inside .title {
  margin-bottom: 12px;
}
.m-documents .delete .inside .action .ac {
  border: none;
  min-width: 73px;
  height: 35px;
  line-height: 35px;
  text-align: center;
  padding: 0 5px;
  opacity: 0.8;
  transition: opacity 0.3s ease;
}
.m-relationships .wrap .name,
.m-relationships .wrap .relationship {
  line-height: 1.1;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  text-align: center;
}
.m-documents .delete .inside .action .ac:hover,
.m-documents .outside:hover .tools {
  opacity: 1;
}
.m-documents .delete .inside .action .ac-cancel {
  background: 0 0;
}
.m-documents .delete .inside .action .ac-yes {
  background: #fff;
  color: #e8332a;
}
.m-documents .outside {
  position: relative;
  border-radius: 4px;
  height: 100%;
}
.m-relationships {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -5px;
}
.m-relationships::after {
  content: '';
  flex: auto;
}
.m-relationships .item {
  width: 6.25%;
  padding: 0 5px;
  margin-bottom: 10px;
  min-width: 122px;
}
.m-relationships .item.active .delete {
  opacity: 1;
  z-index: 15;
}
.m-relationships .item.active .tools {
  opacity: 0 !important;
}
@media screen and (max-width: 1820px) {
  .m-relationships .item {
    width: 6.66667%;
  }
}
@media screen and (max-width: 1720px) {
  .m-relationships .item {
    width: 7.14286%;
  }
}
@media screen and (max-width: 1620px) {
  .m-relationships .item {
    width: 7.69231%;
  }
}
@media screen and (max-width: 1520px) {
  .m-relationships .item {
    width: 8.33333%;
  }
}
@media screen and (max-width: 1420px) {
  .m-relationships .item {
    width: 9.09091%;
  }
}
@media screen and (max-width: 1320px) {
  .m-relationships .item {
    width: 10%;
  }
}
@media screen and (max-width: 1220px) {
  .m-relationships .item {
    width: 11.11111%;
  }
}
@media screen and (max-width: 1120px) {
  .m-relationships .item {
    width: 12.5%;
  }
}
@media screen and (max-width: 1020px) {
  .m-relationships .item {
    width: 14.28571%;
  }
}
@media screen and (max-width: 920px) {
  .m-relationships .item {
    width: 16.66667%;
  }
}
@media screen and (max-width: 820px) {
  .m-relationships .item {
    width: 20%;
  }
}
@media screen and (max-width: 720px) {
  .m-relationships .item {
    width: 25%;
  }
}
@media screen and (max-width: 620px) {
  .m-relationships .item {
    width: 33.33333%;
  }
}
@media screen and (max-width: 520px) {
  .m-relationships .item {
    width: 50%;
  }
}
.m-relationships .wrap {
  background: #fff;
  padding: 5px 5px 10px;
  box-shadow: 0 0 20px #e4dddd;
  border-bottom: 3px solid #2980b9;
  height: 100%;
}
.m-relationships .wrap .thumb {
  margin-bottom: 10px;
  height: 0;
  padding-bottom: 100%;
  position: relative;
}
.m-relationships .wrap .thumb img {
  display: block;
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  object-fit: cover;
}
.m-relationships .wrap .name {
  font-size: 14px;
}
.m-relationships .wrap .relationship {
  font-size: 12px;
  margin-top: 8px;
}
.m-relationships .tools {
  position: absolute;
  left: 0;
  top: 0;
  z-index: 10;
  width: 100%;
  height: 100%;
  background: rgba(41, 128, 185, 0.9);
  opacity: 0;
  transition: opacity 0.3s ease;
}
.m-relationships .ls-tools {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 100%;
  text-align: center;
  font-size: 0;
}
.m-relationships .ls-tools .btn {
  margin: 4px;
  padding: 0;
  width: 40px;
  height: 40px;
  line-height: 38px;
  background: #fff;
  text-align: center;
  outline: 0;
  color: #337ab7;
}
.m-relationships .delete {
  position: absolute;
  left: 0;
  top: 0;
  z-index: 5;
  width: 100%;
  height: 100%;
  background: rgba(232, 51, 42, 0.9);
  display: flex;
  align-items: center;
  opacity: 0;
  transition: opacity 0.3s ease;
}
.m-relationships .delete .inside {
  flex: 1;
  padding: 5px;
  text-align: center;
  color: #fff;
}
.m-relationships .delete .inside .title {
  margin-bottom: 12px;
}
.m-relationships .delete .inside .action .ac {
  border: none;
  min-width: 73px;
  height: 35px;
  line-height: 35px;
  text-align: center;
  padding: 0 5px;
  opacity: 0.8;
  transition: opacity 0.3s ease;
}
.m-relationships .delete .inside .action .ac:hover,
.m-relationships .outside:hover .tools {
  opacity: 1;
}
.m-relationships .delete .inside .action .ac-cancel {
  background: 0 0;
}
.m-relationships .delete .inside .action .ac-yes {
  background: #fff;
  color: #e8332a;
}
.m-relationships .outside {
  position: relative;
  height: 100%;
}
.b-edit-doc .thumb {
  margin-bottom: 10px;
}
.b-edit-doc .thumb > img {
  width: 143px;
  box-shadow: 0 0 10px #d8d8d8;
}
.b-add-doc .upload {
  margin-bottom: 12px;
}
.b-add-doc .upload .input-file {
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
  font-family: inherit;
}
.b-add-doc .upload .input-file + label {
  width: 82px;
  height: 82px;
  border: 1px solid #dedede;
  box-shadow: 0 0 20px #e4dddd;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 12px;
  color: #647678;
}
.b-add-doc .upload .input-file + label span i {
  font-size: 28px;
}
.b-add-doc .upload .input-file + label span span {
  display: block;
  margin-top: 8px;
}

.c-checkbox {
  display: inline-block;
  font-weight: 400;
  line-height: 0;
  vertical-align: middle;
  position: relative;
  cursor: pointer;
  margin: 0;
}
.c-checkbox input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}
.c-checkbox .checkmark {
  display: inline-block;
  height: 18px;
  width: 18px;
  background-color: #fff;
  border: 1px solid #ccc;
  margin-top: 2px;
  position: relative;
}
.c-checkbox .checkmark::after {
  content: '';
  position: absolute;
  display: none;
  left: 5px;
  top: 3px;
  width: 6px;
  height: 8px;
  border: solid #10b507;
  border-width: 0 2px 2px 0;
  -webkit-transform: rotate(40deg);
  -ms-transform: rotate(40deg);
  transform: rotate(40deg);
}
.c-checkbox input.ng-touched.ng-invalid ~ .checkmark,
.c-checkbox input.ng-dirty.ng-invalid ~ .checkmark {
  border-color: $color-error;
}

.c-checkbox input:checked ~ .checkmark::after {
  display: block;
}
.c-checkbox.round .checkmark {
  border-radius: 50%;
}
.c-checkbox.round input:checked ~ .checkmark {
  background-color: #008eb6;
  border-color: #008eb6;
}
.c-checkbox.round input:checked ~ .checkmark::after {
  border-color: #fff;
}
[data-tool-content] {
  display: none;
}
.s-builder {
  height: calc(100% - 110px);
  transition: all 0.5s ease;
}
.s-builder.active {
  margin-right: 410px;
}
.s-builder__inner {
  position: relative;
  height: 100%;
}
.i-builder-control {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 35px;
  height: 35px;
  text-align: center;
  background: #ebebeb;
  border: 1px solid #d8d8d8;
  border-radius: 50%;
  font-size: 18px;
  color: $color-primary;
  cursor: pointer;
  position: relative;
  -webkit-box-shadow: 0 0 5px 1px rgba(0, 0, 0, 0.1);
  box-shadow: 0 0 5px 1px rgba(0, 0, 0, 0.1);
}
.i-builder-control.active {
  z-index: 9999;
}

.cok-btn-close,
.cok-downloads__header,
.m-activity__data {
  height: calc(100vh - 285px);
  overflow: auto;
}
.m-activity__data table {
  width: 100%;
  table-layout: fixed;
}
.m-activity__data table td,
.m-activity__data table th {
  padding: 10px;
  vertical-align: middle;
}
.m-activity__data table thead th {
  background: #f9f9f9;
  color: #1b4967;
}
.m-activity__data table tbody tr:nth-child(even) td,
.m-activity__data table tbody tr:nth-child(even) th {
  background: rgba(249, 249, 249, 0.5);
}
.m-activity__data table tbody td,
.m-activity__data table tbody th {
  border-bottom: 1px solid #eee;
  word-break: break-word;
}
.m-activity__data table tbody td.truncate,
.m-activity__data table tbody th.truncate {
  text-overflow: ellipsis;
  width: 100%;
  overflow: hidden;
}
.m-activity__data table tbody a:hover {
  text-decoration: underline;
}

.cok-datetime-picker {
  position: relative;
  width: 100%;

  .datetime-input {
    left: 0;
    top: 0;
    right: 0;
    overflow: hidden;
  }

  .calendar-icon {
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translate(0, -50%);
    cursor: pointer;
  }
}

// Styling for printing pages
@media print {
  body {
    div.result-container {
      break-inside: avoid;
    }
  }
  body, .wrapper, perfect-scrollbar {
    overflow: visible !important;
  }
  .ps.ps--active-y {
    position: relative !important;
    overflow: visible !important;
    height: 100% !important;
  }
}

.btn-close {
  z-index: 100;
}

.cursor-pointer {
  cursor: pointer;
}

.textarea-expanded {
  min-height: 80px !important;
}
