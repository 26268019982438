$color-error: #ff530d;
$color-warning: #f0ad4e;

$color-primary: var(--color-primary);
$color-secondary: var(--color-secondary);
$header-color: var(--header-color);
$header-link-color: var(--header-link-color);
$header-active-link-color: var(--header-active-link-color);
$header-border-color: var(--header-border-color);
$color-white: #ffffff;

:root {
  --color-primary: rgb(29, 100, 145);
  --color-secondary: #333;
  --header-color: rgb(29, 100, 145);
  --header-link-color: rgba(255, 255, 255, 0.55);
  --header-active-link-color: #fff;
  --header-border-color: rgba(0, 0, 0, 0);
}
