@import 'colors.scss';

#colors li,
.box-search,
.styled-select {
  position: relative;
}

.fileupload .btn {
  -webkit-transition: border-color ease-in-out 0.15s, -webkit-box-shadow ease-in-out 0.15s;
  -o-transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
}

.fileupload-preview {
  padding: 8px 10px;
  background-image: none;
}

.styled-select {
  min-width: 90px;
}

.box-search .fa-search, .box-search .fa-magnifying-glass {
  position: absolute;
  right: 10px;
  top: 12px;
  font-size: 10px;
  color: #8c9091;
  cursor: pointer;
}

input[type='radio'],
input[type='checkbox'] {
  margin-right: 10px;
}

select {
  width: 100%;
}

.fileupload .btn,
.fileupload-preview {
  font-family: 'Open Sans', Arial, sans-serif;
  font-weight: 400;
  color: #647678;
  background-color: #fff;
}

.input-block-level {
  display: block;
  width: 100%;
  min-height: 30px;
  box-sizing: border-box;
}

.fileupload .thumbnail,
.fileupload .thumbnail > img,
.fileupload .uneditable-input {
  display: inline-block;
  vertical-align: middle;
}

.btn-file {
  position: relative;
  vertical-align: middle;
}

.btn-file > input {
  position: absolute;
  top: 0;
  right: 0;
  margin: 0;
  opacity: 0;
  filter: alpha(opacity=0);
  transform: translate(-300px, 0) scale(4);
  font-size: 23px;
  direction: ltr;
  cursor: pointer;
}

.fileupload .uneditable-input {
  margin-bottom: 0;
  cursor: text;
}

.fileupload .thumbnail {
  overflow: hidden;
  margin-bottom: 5px;
  text-align: center;
}

.fileupload .thumbnail > img {
  max-height: 100%;
}

.box,
.fileupload .btn,
.fileupload-preview {
  text-align: center;
  box-shadow: 0 0 20px #dedede;
}

.fileupload {
  margin-bottom: 9px;
  width: 345px;
  position: relative;
}

.fileupload .btn {
  vertical-align: middle;
  position: absolute;
  top: 0;
  right: 0;
  width: 39px;
  height: 35px;
  font-size: 25px;
  line-height: 30px;
  background-image: none;
  background-clip: padding-box;
  border: 1px solid #fff;
  border-radius: 3px;
  transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
}

.box,
.box .inner {
  position: relative;
  overflow: hidden;
}

.fileupload .close {
  display: none !important;
}

.box.add p.name,
.box.add.finished p.name,
.box.small p.name {
  font: 400 10px/9px 'Open Sans';
}

.list .box,
.medium .box {
  width: 110px;
  height: 160px;
}

.list .box .picture,
.medium .box .picture {
  width: 100px;
  height: 100px;
}

.list .box p.name,
.medium .box p.name {
  margin: 11px 12px 0;
}

.big .box {
  width: 167px;
  height: 323px;
}

.big .box .picture {
  width: 158px;
  height: 158px;
}

.big .box p.name {
  margin: 6px 4px 0;
}

.box {
  color: $color-secondary;
  margin-bottom: 10px;
  transition: width 0.3s ease-in-out, height 0.3s ease-in-out;
}

.box .inner {
  width: 100%;
  height: 100%;
  padding: 4px;
  background: #fff;
  border-bottom: 3px solid #fff;
}

.box.blue .bottom,
.box.blue .bottom p.p12,
.box.blue .top p.p10 {
  background-color: #2980b9;
}

.box.green .inner {
  border-color: #29d9c2;
}

.box.green p.name {
  color: #29d9c2;
}

.box.blue .inner {
  border-color: #2980b9;
}

.box.blue .bottom p.p08 {
  color: #2980b9;
}

.box.gray .bottom,
.box.gray .bottom p.p12,
.box.gray .top p.p10 {
  background-color: #d8d8d8;
}

.box.blue .bottom p.more-info {
  color: #fff;
}

.box.gray .inner {
  border-color: #d8d8d8;
}

.box.gray .bottom p.p08 {
  color: #d8d8d8;
}

.box.red .bottom,
.box.red .bottom p.p12,
.box.red .top p.p10 {
  background-color: #d57373;
}

.box.red .inner {
  border-color: #d57373;
}

.box.red .bottom p.p08 {
  color: #d57373;
  padding-bottom: 4px;
}

.box.small {
  width: 75px;
  height: 109px;
}

.box.small .inner {
  border-width: 2px;
  padding: 2px;
}

.box.small .picture {
  width: 67px;
  height: 67px;
}

.box.small p.name {
  padding-top: 10px;
}

.box.add {
  width: 75px;
  height: 109px;
}

.box.add.red p.name {
  color: #d57373;
}

.box.add .inner {
  border-style: dotted;
  border-width: 2px;
}

.box.add p.name {
  text-transform: uppercase;
  padding-top: 17px;
}

.box.add .picture {
  width: 50px;
  height: 50px;
  margin: 6px 0 3px 7.5px;
}

.box.add.finished .inner {
  border-style: solid;
  border-width: 1px;
}

.box.add.finished p.p08 {
  padding-top: 14px;
}

.box.add.finished p.name {
  padding-top: 0;
  text-transform: capitalize;
}

.box .picture {
  overflow: hidden;
  background-size: cover;
}

.box p {
  margin: 0;
  padding: 0;
}

.box p.name {
  line-height: 1.25;
}

.box .gender {
  opacity: 0.5;
  border-radius: 0;
  width: 10px;
  height: 10px;
}

.box p.p12 {
  font: 400 12px 'Open Sans';
}

.box p.p10 {
  font: 500 10px/11px 'Open Sans';
}

.box p.p08 {
  font: 500 8px 'Open Sans';
}

.box .lable {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background: #ff530d;
  position: absolute;
  right: 2px;
  top: 2px;
  transform: rotate(45deg);
  z-index: 1;
}

.box .bottom,
.box .top {
  width: 100%;
  position: absolute;
  left: 0;
}

.icon.thumb-large,
.icon.thumb-small {
  line-height: 24px;
  background-size: cover;
  cursor: pointer;
  font-size: 18px;
}

.box .top {
  top: 0;
}

.box .top p.p10 {
  text-transform: uppercase;
  color: #fff;
  padding: 2px 0;
}

.box .bottom {
  bottom: 0;
}

.box .bottom p.p08 {
  text-transform: uppercase;
  padding-bottom: 1px;
}

.box .bottom p.p12 {
  padding: 3px 0;
  color: #fff;
}

.icon {
  width: 24px;
  height: 24px;
  display: inline-block;
  transition: all 0.3s;
}

.icon.thumb-small {
  color: #8c9091;
}

.icon.thumb-small.active .fa {
  color: #21b5f7;
}

.icon.thumb-large {
  color: #8c9091;
}

.icon.thumb-large.active .fa {
  color: #21b5f7;
}

.icon.list-view {
  background: url(../images/list-view.svg) center center no-repeat;
  background-size: contain;
  cursor: pointer;
}

.icon.list-view.active,
.icon.list-view:hover {
  background-image: url(../images/list-view-active.svg);
}

.icon.plus {
  background: url(../images/plus.svg) no-repeat;
  background-size: cover;
  cursor: pointer;
}

.icon.plus:hover {
  background-image: url(../images/plus-active.svg);
}

.icon.bell {
  content: '\f0f3';
  width: 18px;
  height: 18px;
  cursor: pointer;
  font-family: FontAwesome;
  font-size: 18px;
  line-height: 16px;
  color: #fff;
}

.icon.bell.active,
.icon.bell:hover {
  color: #21b5f7;
}

.icon.human {
  width: 32px;
  height: 32px;
  background: url(../images/human.svg) no-repeat;
  background-size: cover;
}

.icon.close,
.icon.menu {
  width: 24px;
  height: 24px;
  cursor: pointer;
}

.icon.menu {
  background: url(../images/menu.svg) no-repeat;
  background-size: cover;
}

.icon.menu:hover {
  background-image: url(../images/menu-active.svg);
}

.icon.close {
  background: url(../images/close.svg) center center no-repeat;
  background-size: cover;
}

.icon.close:hover {
  background-image: url(../images/close-active.svg);
}
